const metadata = {
  main: {
    title:
      'Gut vorsorgen: Patientenverfügung, Vorsorgeauftrag & Testament in 20 Minuten',
    description:
      'Wir verhelfen Menschen zu einem gut geregelten Leben, indem wir die essenziellen Dinge in der Vorsorge radikal vereinfachen.',
    keywords: {
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
      lastWill: 'Testament',
      personalPreferences: 'Persönliche Vorsorge',
      retirementPlanning: 'Pensionsplanung',
      retirementProvision: 'Altersvorsorge',
    },
  },
  living_will: {
    title: 'Patientenverfügung in 5 min online und rechtssicher erstellen',
    description:
      'Erstellen Sie eine Patientenverfügung und ersparen Sie Angehörigen im Extremfall zusätzlichen Stress.',
    keywords: {
      livingWill: 'Patientenverfügung',
      printLivingWill: 'Patientenverfügung ausdrucken',
      directive: 'Patientenverfuegung',
      printDirective: 'Patientenverfügungen zum ausdrucken',
    },
  },
  power_of_attorney: {
    title: 'Vorsorgeauftrag in 5 min online und rechtssicher erstellen',
    description:
      'Mit dem Vorsorgeauftrag klären Sie proaktiv, wer Sie im Falle einer Handlungsunfähigkeit vertritt und für Sie einsteht.',
    keywords: {
      powerOfAttorney: 'Vorsorgeauftrag',
      powerOfAttorneyTemplate: 'Vorsorgeauftrag Vorlage',
      powerOfAttorneyTemplate2: 'Vorlage Vorsorgeauftrag',
      powerOfAttorneyTemplate3: 'Vorlage für Vorsorgeauftrag',
      powerOfAttorney2: 'Vorsorgeauftrag',
    },
  },
  last_will: {
    title: 'Testament in 10 min online und rechtssicher erstellen',
    description:
      'Halten Sie in Ihrem Testament fest, was mit Ihrem Nachlass geschieht und wer was erben wird. Jetzt einfach vorsorgen.',
    keywords: {
      lastWill: 'Testament',
      share: 'Pflichtteil Erbe',
      share2: 'Erbe Pflichtteil',
      estate: 'Nachlass',
      succession: 'Gesetzliche Erbfolge',
    },
  },
  marriage_contract: {
    title: 'Ehevertrag in 10 min online und rechtssicher erstellen',
    description:
      'Schützen Sie sich und Ihren Ehegatten mit einem Ehevertrag, der die erbrechtlichen Gegebenheiten regelt und doofe Situationen erspart.',
    keywords: {
      lastWill: 'Ehevertrag',
      share: 'Pflichtteil Erbe',
      share2: 'Ehevetrag Immobilienkauf',
      estate: 'Nachlass',
      succession: 'Gesetzliche Erbfolge',
    },
  },
  partner_with_us: {
    title: 'Erweitern Sie Ihr Vorsorgeangebot mit gut geregelt',
    description:
      'Nutzen Sie die Whitelabel-Lösung von gut geregelt, um Ihren Kunden umfassende Vorsorge zugänglich zu machen. Integriert sich auch in Ihren Beratungsprozess.',
    keywords: {
      pensionFoundation: 'Vorsorgestiftung',
      solution: 'Vorsorgelösung',
      trustees: 'Stiftungsrat',
      advice: 'Vorsorgeberatung',
      advisor: 'Vorsorgeberater',
    },
  },
  digital_footprint: {
    title: 'Digitaler Nachlass regeln',
    description:
      'Regeln Sie Ihren digitalen Nachlass und definieren Sie, was mit Ihren Abonnementen, Social-Media-Accounts, Kryptowährungen & Co. nach Ihrem Tod geschieht.',
  },
  register_assets: {
    title: 'Vermögens- und Sachregister pflegen',
    description:
      'Dokumentieren Sie Ihr physisches und finanzielles Vermögen und sparen Sie Ihren Angehörigen Dutzende von Stunden beim Aufräumen.',
  },
  grosseItern: {
    title: 'Grosseltern Magazin Spezialangebot',
  },
  preventiveCheckUp: {
    title: 'Vorsorgecheck',
    description:
      'Mit dem Vorsorgecheck können Sie Ihre Vorsorgeplanung überprüfen und gegebenenfalls anpassen.',
    keywords: {
      pensionFoundation: 'Vorsorgecheck',
      solution: 'Haftungsrisiko',
      trustees: 'Stiftungsrat',
      advice: 'Vorsorgeberatung',
      advisor: 'Pensionskasse',
    },
  },
  cohabitation_agreement: {
    title: 'Konkubinatsvertrag',
    description:
      'Mit dem Konkubinatsvertrag regeln Sie die finanziellen und rechtlichen Aspekte Ihrer Partnerschaft.',
  },
};
export default metadata;
