const validations = {
  user: {
    name: 'Name ist erforderlich',
    first_name: 'Vorname ist erforderlich',
    last_name: 'Nachname ist erforderlich',
    email: 'E-Mail ist erforderlich',
    invalidEmail: 'E-Mail ist ungültig',
    civil_status: 'Familienstand ist erforderlich',
    gender: 'Bitte wählen Sie Ihr Geschlecht aus',
    place_of_citinzenship: 'Heimatort ist erforderlich',
    date_of_birth: {
      required: 'Geburtsdatum ist erforderlich',
      max: 'Vertreter muss 18 Jahre oder älter sein',
    },
    mobile_phone: {
      required: 'Handynummer ist erforderlich',
      min: 'Die Handynummer ist zu kurz',
      max: 'Die Handynummer ist zu lang',
      matches: 'Die Handynummer ist ungültig',
    },
    minPassword: 'Das Passwort muss mindestens 6 Zeichen lang sein',
    password: 'Passwort ist erforderlich',
    confirmPassword: 'Passwort bestätigen ist erforderlich',
    passwordMatch: 'Passwörter müssen übereinstimmen',
  },
  adress: {
    street: 'Strasse ist erforderlich',
    house_number: 'Hausnummer ist erforderlich',
    zip_code: 'Postleitzahl ist erforderlich',
    city: 'Stadt ist erforderlich',
    country: 'Land ist erforderlich',
  },
  invalid_date: 'Ungültiges Datum',
  platform_logo: 'Das Bild sollte mindestens 630 x 80 Pixel gross sein',
  primary_representative: 'Hauptvertreter ist erforderlich',
  secondary_representative: 'Stellvertreter ist erforderlich',
  rep_selection: 'Bitte wählen Sie Haupt- und Stellvertreter aus',
  rep_inform_date: 'Bitte wählen Sie das Informationsdatum',
  selectAnOption: 'Wählen Sie eine Option',
  current_rental_agreement_name:
    'Name des aktuellen Mietvertrags ist erforderlich',
  description_good: 'Beschreibung ist erforderlich',
  storage_location: 'Speicherort ist erforderlich',
  next_benefiter: 'Nächster Begünstigter ist erforderlich',
  previous_owner: 'Vorbesitzer ist erforderlich',
  who_are_you: 'Bitte sag mir, wer du bist',
  shouldNotBeEmpty: 'Dieses Feld sollte nicht leer sein',
  default_message: 'Dieses Feld ist erforderlich',
  meet_year: 'Jahr des Treffens ist erforderlich',
  must_be_number: 'Dieses Feld sollte eine Nummer sein',
  min_0: 'Dieses Feld sollte mindestens 0 sein',
  max_100: 'Dieses Feld sollte maximal 100 sein',
  payment_ratio_per_party: 'Zahlungsverhältnis pro Partei ist erforderlich',
  date_of_moving_in: 'Einzugsdatum ist erforderlich',
  successors: {
    first_name: 'Vorname ist erforderlich',
    last_name: 'Nachname ist erforderlich',
    email: 'E-Mail ist erforderlich',
    invalidEmail: 'E-Mail ist ungültig',
    agree: 'Bitte stimmen Sie zu, dass diese Personen Gastzugang erhalten.',
    min: 'Bitte wählen Sie mindestens zwei Vertrauenspersonen aus',
  },
  message: 'Nachricht ist erforderlich',
  role: 'Rolle ist erforderlich',
  shouldBeNumber: 'Dieses Feld sollte eine Nummer sein',
  duplicateChild: 'Es gibt ein Kind mit demselben Namen',

  marriage: {
    place_of_marriage: 'Ort der Eheschliessung ist erforderlich',
    date_of_marriage: 'Datum der Eheschliessung ist erforderlich',
    have_children: 'Bitte wählen Sie eine Option',
    number_of_children: 'Anzahl der Kinder ist erforderlich',
    is_less_than_children:
      'Die Anzahl der Kinder muss mit der Listenanzahl übereinstimmen',
    childrenAmount:
      'Die Anzahl der Kinder muss mit der Listenanzahl übereinstimmen',
    acquired_after_marriage: 'Bitte wählen Sie eine Option',
    acquired_property_after_marriage: 'Bitte wählen Sie eine Option',
    maintain_ordinary_property_regime: 'Bitte wählen Sie eine Option',
    spouse_disposal_right: 'Bitte wählen Sie eine Option',
    disposal_fixed_amount: 'Fester Betrag ist erforderlich',
    disposal_percentage: 'Prozentsatz ist erforderlich',
    executor: 'Vollstrecker ist erforderlich',
    deputy: 'Stellvertreter ist erforderlich',
    sameAsContractParties:
      'Die Vertragsparteien können nicht als Vollstrecker oder Stellvertreter ausgewählt werden',
    assets: {
      description_good: 'Beschreibung ist erforderlich',
      approximate_value: 'Annähernder Wert ist erforderlich',
      approximate_value_number: 'Der Wert muss eine Nummer sein',
      storage_location: 'Speicherort ist erforderlich',
      previous_owner: 'Vorbesitzer ist erforderlich',
    },
  },

  digitalAssets: {
    assets: {
      description: 'Beschreibung ist erforderlich',
      storage_location: 'Speicherort ist erforderlich',
      beneficiary_id: 'Begünstigter ist erforderlich',
    },
    financial: {
      institution_name: 'Name der Institution ist erforderlich',
      type: 'Typ ist erforderlich',
    },
  },

  importantInformation: {
    passport_storage_location: 'Passspeicherort ist erforderlich',
    identity_card_storage_location:
      'Speicherort des Personalausweises ist erforderlich',
    residence_permit_storage_location:
      'Speicherort der Aufenthaltsbewilligung ist erforderlich',
    family_book_storage_location: 'Familienbuchspeicherort ist erforderlich',
    birth_certificate_storage_location:
      'Speicherort der Geburtsurkunde ist erforderlich',
    bank_documents_storage_location:
      'Bankdokumentenspeicherort ist erforderlich',
    ahv_fund: 'AHV-Fonds ist erforderlich',
    ahv_number: 'AHV-Nummer ist erforderlich',
    pension_fund_organization: 'Pensionskassenorganisation ist erforderlich',
    trusted_person: 'Willensvollstrecker ist erforderlich',
    insurancePolicySchema: {
      insurance_type: 'Versicherungstyp ist erforderlich',
      insurance_company: 'Versicherungsgesellschaft ist erforderlich',
      policy_number: 'Versicherungsnummer ist erforderlich',
      storage_location: 'Speicherort ist erforderlich',
    },
    threeASchema: {
      pension_type: 'Rententyp ist erforderlich',
      financial_institution: 'Finanzinstitut ist erforderlich',
      storage_location: 'Speicherort ist erforderlich',
    },
    notarySchema: {
      name: 'Name ist erforderlich',
      email: 'E-Mail ist erforderlich',
      invalidEmail: 'E-Mail ist ungültig',
    },
  },

  acceptedFiles: {
    type: {
      image: 'Nur Bilder sind erlaubt',
      png: 'Nur PNG-Dateien sind erlaubt',
      jpg: 'Nur JPG-Dateien sind erlaubt',
      txt: 'Nur TXT-Dateien sind erlaubt',
      docx: 'Nur DOCX-Dateien sind erlaubt',
      pdf: 'Es sind nur PDF- oder Word-Dokumente zulässig.',
    },
  },
};

export default validations;
